import h, { levels as s } from "pino";
import { default as U } from "pino";
import { safeJsonStringify as u } from "@walletconnect/safe-json";
const c = {
    level: "info"
  },
  n = "custom_context",
  l = 1e3 * 1024;
class O {
  constructor(e) {
    this.nodeValue = e, this.sizeInBytes = new TextEncoder().encode(this.nodeValue).length, this.next = null;
  }
  get value() {
    return this.nodeValue;
  }
  get size() {
    return this.sizeInBytes;
  }
}
class d {
  constructor(e) {
    this.head = null, this.tail = null, this.lengthInNodes = 0, this.maxSizeInBytes = e, this.sizeInBytes = 0;
  }
  append(e) {
    const t = new O(e);
    if (t.size > this.maxSizeInBytes) throw new Error(`[LinkedList] Value too big to insert into list: ${e} with size ${t.size}`);
    for (; this.size + t.size > this.maxSizeInBytes;) this.shift();
    this.head ? (this.tail && (this.tail.next = t), this.tail = t) : (this.head = t, this.tail = t), this.lengthInNodes++, this.sizeInBytes += t.size;
  }
  shift() {
    if (!this.head) return;
    const e = this.head;
    this.head = this.head.next, this.head || (this.tail = null), this.lengthInNodes--, this.sizeInBytes -= e.size;
  }
  toArray() {
    const e = [];
    let t = this.head;
    for (; t !== null;) e.push(t.value), t = t.next;
    return e;
  }
  get length() {
    return this.lengthInNodes;
  }
  get size() {
    return this.sizeInBytes;
  }
  toOrderedArray() {
    return Array.from(this);
  }
  [Symbol.iterator]() {
    let e = this.head;
    return {
      next: () => {
        if (!e) return {
          done: !0,
          value: null
        };
        const t = e.value;
        return e = e.next, {
          done: !1,
          value: t
        };
      }
    };
  }
}
class L {
  constructor(e, t = l) {
    this.level = e ?? "error", this.levelValue = s.values[this.level], this.MAX_LOG_SIZE_IN_BYTES = t, this.logs = new d(this.MAX_LOG_SIZE_IN_BYTES);
  }
  forwardToConsole(e, t) {
    t === s.values.error ? console.error(e) : t === s.values.warn ? console.warn(e) : t === s.values.debug ? console.debug(e) : t === s.values.trace ? console.trace(e) : console.log(e);
  }
  appendToLogs(e) {
    this.logs.append(u({
      timestamp: new Date().toISOString(),
      log: e
    }));
    const t = typeof e == "string" ? JSON.parse(e).level : e.level;
    t >= this.levelValue && this.forwardToConsole(e, t);
  }
  getLogs() {
    return this.logs;
  }
  clearLogs() {
    this.logs = new d(this.MAX_LOG_SIZE_IN_BYTES);
  }
  getLogArray() {
    return Array.from(this.logs);
  }
  logsToBlob(e) {
    const t = this.getLogArray();
    return t.push(u({
      extraMetadata: e
    })), new Blob(t, {
      type: "application/json"
    });
  }
}
class m {
  constructor(e, t = l) {
    this.baseChunkLogger = new L(e, t);
  }
  write(e) {
    this.baseChunkLogger.appendToLogs(e);
  }
  getLogs() {
    return this.baseChunkLogger.getLogs();
  }
  clearLogs() {
    this.baseChunkLogger.clearLogs();
  }
  getLogArray() {
    return this.baseChunkLogger.getLogArray();
  }
  logsToBlob(e) {
    return this.baseChunkLogger.logsToBlob(e);
  }
  downloadLogsBlobInBrowser(e) {
    const t = URL.createObjectURL(this.logsToBlob(e)),
      o = document.createElement("a");
    o.href = t, o.download = `walletconnect-logs-${new Date().toISOString()}.txt`, document.body.appendChild(o), o.click(), document.body.removeChild(o), URL.revokeObjectURL(t);
  }
}
class B {
  constructor(e, t = l) {
    this.baseChunkLogger = new L(e, t);
  }
  write(e) {
    this.baseChunkLogger.appendToLogs(e);
  }
  getLogs() {
    return this.baseChunkLogger.getLogs();
  }
  clearLogs() {
    this.baseChunkLogger.clearLogs();
  }
  getLogArray() {
    return this.baseChunkLogger.getLogArray();
  }
  logsToBlob(e) {
    return this.baseChunkLogger.logsToBlob(e);
  }
}
var x = Object.defineProperty,
  S = Object.defineProperties,
  _ = Object.getOwnPropertyDescriptors,
  p = Object.getOwnPropertySymbols,
  T = Object.prototype.hasOwnProperty,
  z = Object.prototype.propertyIsEnumerable,
  f = (r, e, t) => e in r ? x(r, e, {
    enumerable: !0,
    configurable: !0,
    writable: !0,
    value: t
  }) : r[e] = t,
  i = (r, e) => {
    for (var t in e || (e = {})) T.call(e, t) && f(r, t, e[t]);
    if (p) for (var t of p(e)) z.call(e, t) && f(r, t, e[t]);
    return r;
  },
  g = (r, e) => S(r, _(e));
function k(r) {
  return g(i({}, r), {
    level: r?.level || c.level
  });
}
function v(r, e = n) {
  return r[e] || "";
}
function b(r, e, t = n) {
  return r[t] = e, r;
}
function y(r, e = n) {
  let t = "";
  return typeof r.bindings > "u" ? t = v(r, e) : t = r.bindings().context || "", t;
}
function w(r, e, t = n) {
  const o = y(r, t);
  return o.trim() ? `${o}/${e}` : e;
}
function E(r, e, t = n) {
  const o = w(r, e, t),
    a = r.child({
      context: o
    });
  return b(a, o, t);
}
function C(r) {
  var e, t;
  const o = new m((e = r.opts) == null ? void 0 : e.level, r.maxSizeInBytes);
  return {
    logger: h(g(i({}, r.opts), {
      level: "trace",
      browser: g(i({}, (t = r.opts) == null ? void 0 : t.browser), {
        write: a => o.write(a)
      })
    })),
    chunkLoggerController: o
  };
}
function I(r) {
  var e;
  const t = new B((e = r.opts) == null ? void 0 : e.level, r.maxSizeInBytes);
  return {
    logger: h(g(i({}, r.opts), {
      level: "trace"
    }), t),
    chunkLoggerController: t
  };
}
function A(r) {
  return typeof r.loggerOverride < "u" && typeof r.loggerOverride != "string" ? {
    logger: r.loggerOverride,
    chunkLoggerController: null
  } : typeof window < "u" ? C(r) : I(r);
}
export { l as MAX_LOG_SIZE_IN_BYTES_DEFAULT, n as PINO_CUSTOM_CONTEXT_KEY, c as PINO_LOGGER_DEFAULTS, w as formatChildLoggerContext, E as generateChildLogger, C as generateClientLogger, A as generatePlatformLogger, I as generateServerLogger, v as getBrowserLoggerContext, k as getDefaultLoggerOptions, y as getLoggerContext, U as pino, b as setBrowserLoggerContext };
