class e {}
class o extends e {
  constructor(c) {
    super();
  }
}
class n extends e {
  constructor() {
    super();
  }
}
class r extends n {
  constructor(c) {
    super();
  }
}
export { n as IBaseJsonRpcProvider, e as IEvents, o as IJsonRpcConnection, r as IJsonRpcProvider };
